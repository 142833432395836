import React, { useState } from "react";
import {
  Navbar,
  Typography,
  IconButton,
  Button,
  Input,
  Card,
} from "@material-tailwind/react";

import {
  MailIcon,
  BellIcon,
  ChevronDownIcon,
  UserIcon,
  LogoutIcon,
  MenuIcon,
} from "@heroicons/react/outline";
import "../css/css1.css";
export function NavbarDark() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  return (
    <Navbar
      variant="gradient"
      color="white"
      style={{
        // Remove padding
        left: "20%", // Remove margin
        width: "150%", // Set your desired width here
      }}
      className=" sticky top-0 z-10 h-max max-w-full rounded-none py-2 px-4 lg:px-8 lg:py-4"
    >
      <div className="flex flex-wrap justify-between gap-y-6 text-black">
  {/* Card content */}
  <div className="flex justify-between gap-5 md:mr-50">
    <img
      src="./images/stock.jpeg"
      alt="Your Image"
      style={{ width: "50%" }}
      className="w-5 h-10"
    />
    <div className="flex items-center"> {/* Use items-center to vertically align icon and text */}
      {/* Add margin-right (mr-2) for spacing */}
    <h4>  DashBord</h4>
    </div>
  </div>


        <div className=" flex gap-1 md:mr-4">
          <IconButton variant="text" color="black">
            <MailIcon className="h-6 w-4" />
          </IconButton>
          <IconButton variant="text" color="black">
            <BellIcon className="h-4 w-4" />
          </IconButton>
          <IconButton variant="text" color="black" onClick={toggleDropdown}>
            <ChevronDownIcon className="h-6 w-4" />
          </IconButton>
          {isDropdownOpen && (
            <div className="absolute right-0 mt-10 bg-white border rounded shadow-md">
              <ul className="justify-center">
                <li className="mr-4">
                  <div className="flex items-center gap-2">
                    <UserIcon className="h-4 w-4 text-blue-500" />{" "}
                    {/* Adjust icon size and color */}
                    <Typography color="black" className="text-sm">
                      Profile
                    </Typography>{" "}
                    {/* Adjust text size */}
                  </div>
                </li>
                <li>
                  <div className="flex items-center gap-2">
                    <LogoutIcon className="h-4 w-4 text-red-500" />{" "}
                    {/* Adjust icon size and color */}
                    <Typography color="black" className="text-sm">
                      Logout
                    </Typography>{" "}
                    {/* Adjust text size */}
                  </div>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </Navbar>
  );
}
export default NavbarDark;
