// Inscription.jsx
import React, { useState } from 'react';
import axios from 'axios';

import "bootstrap/dist/css/bootstrap.css";
// src/index.css
import "font-awesome/css/font-awesome.min.css";

function Inscription() {
  // Your component logic here
  const [formData, setFormData] = useState({
    name: '',
    prenom: '',
    adresse: '',
    contact: '',
    email: '',
    password: '',
    confirmPassword: ''
  });

  const handleForm = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      // Passwords do not match, handle the error here (e.g., display a message to the user)
      console.log("Password:", formData.password);
      console.log("Confirm Password:", formData.confirmPasswor);
      return;
    }

    try {
      const response = await axios.post('http://localhost:8000/api/register', formData);
      if (response.status === 200) {
        // Registration was successful
        window.location.href = '/login'; // Redirect to login page on success
      } else {
        // Registration failed, handle errors
        console.log('Registration failed', response.data);
        // You can display an error message to the user
      }
    } catch (error) {
      // Handle any network or request errors here
      console.error('Network error', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  return (
    // JSX for the component
    <div className="flex-container  custom-container">
      <div className="d-flex justify-content-center">
        <div className=" col-md-5 section1 ">
          <div className='d-flex flex-column align-items-center'>
            <img
              src="./images/stock.jpeg"
              alt="images"
              style={{ width: "10%", display: "inline-block" }}
            ></img>
          {""} <br></br>
          <div>
            <p >Bienvenu à Mstock</p>
          </div> <br></br>
          <div className=' p-8 d-flex flex-column align-items-center'>
          <img src="./images/inscri.svg" style={{ width: "60%" }}></img>
          </div>
        </div>
        </div>
        <div className=" p-3 col-md-5 section2">
          <div className="d-flex flex-column align-items-center">
            <p style={{padding: "0"}} id="id">Sign In</p>
            <form onSubmit={handleForm}>
              <div className="form-group">
                <div className="inputbox ">
                  <label>Nom</label>
                  <br />
                  <div className="custom-input">
                    <i className="fa fa-user white-icon"></i>
                    <input  type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            placeholder="Name"className="form-control form-control-lg" style={{ paddingLeft: '60px' }} />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="inputbox ">
                  <label>Prenom</label>
                  <br />
                  <div className="custom-input">
                  <i className="fa fa-user white-icon" style={{ display: 'inline-block', marginRight: '10px' }}></i>
                   <input  type="text"
            name="prenom"
            value={formData.prenom}
            onChange={handleInputChange}
            placeholder="Prenom" className="form-control form-control-lg" style={{ paddingLeft: '60px' }}/>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="inputbox ">
                  <label>Email</label>
                  <br />
                  <div className="custom-input">
                  <i className="fa fa-envelope white-icon"></i>
                    <input  type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder="Email "className="form-control form-control-lg"  style={{ paddingLeft: '60px' }} />

                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="inputbox ">
                  <label>Adresse</label>
                  <br />
                  <div className="custom-input">
                    <i className="fa fa-address-card-o white-icon"></i>
                    <input type="text"
            name="adresse"
            value={formData.adresse}
            onChange={handleInputChange}
            placeholder="Adresse" className="form-control form-control-lg" style={{ paddingLeft: '60px' }} />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="inputbox ">
                  <label>contact</label>
                  <br />
                  <div className="custom-input">
                    <i className="fa fa-phone-square white-icon"></i>
                    <input type="text"
            name="contact"
            value={formData.contact}
            onChange={handleInputChange}
            placeholder="Contact" className="form-control form-control-lg"  style={{ paddingLeft: '60px' }}/>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="inputbox ">
                  <label>Password</label>
                  <br />
                  <div className="custom-input">
                    <i className="fa fa-lock white-icon"></i>
                    <input
                      type="password"
                      name="password"
            value={formData.password}
            onChange={handleInputChange}
            placeholder="Password"
                      className="form-control form-control-lg" style={{ paddingLeft: '60px' }}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="inputbox ">
                  <label>Confirm_password</label>
                  <br />
                  <div className="custom-input">
                    <i className="fa fa-lock white-icon"></i>
                    <input
  type="password"
  name="confirmPassword" // Check the name here
  value={formData.confirmPassword}
  onChange={handleInputChange}
  placeholder="Confirm Password"
  className="form-control form-control-lg"
  style={{ paddingLeft: '60px' }}
/>
                  </div>
                </div>
              </div><br></br>
              <div>
                <button type="submit" className=" p-2 align-items-center btn btn-primary">
                  {" "}
                  Connecter
                </button>
              </div> <br></br>
              
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Inscription; // Ensure you export the component
