import React, { useEffect,useState } from "react"; // Import useState from React
import axios from 'axios';
import Cookies from 'js-cookie';
import "../css/inscription.css";
import "bootstrap/dist/css/bootstrap.css";
// src/index.css
import "font-awesome/css/font-awesome.min.css";

function Login() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [userId, setUserId] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  useEffect(() => {
    // Retrieve the user_id cookie when the component mounts
    const storedUserId = Cookies.get('user_id');
    console.log('Stored User ID:', storedUserId); // Add this line
    if (storedUserId) {
        setUserId(storedUserId);
    }
}, []);
  const handleForm = async (e) => {
    e.preventDefault();
  console.log(formData);
    try {
      const response = await axios.post('http://localhost:8000/api/login', {
        email: formData.email,
        password: formData.password,
      });
  
      if (response.status === 200) {
        // Assuming the user ID is within response.data.user.id
        const userId = response.data.user.id;
        
        // Access the tokenable_id from the access_token object
        const tokenableId = response.data.access_token.tokenable_id;
      
        // Define and log the accessToken (from your response)
        const accessToken = response.data.access_token;
      
        console.log('User ID:', userId);
        console.log('Tokenable ID:', tokenableId);
      
        // Store the accessToken in a cookie
        Cookies.set('access_token', accessToken, { expires: 7 });
        Cookies.set('user_id', userId, { expires: 7 });
      
        setUserId(userId);
        window.location.href = '/';
     
      
      } else {
        // Handle login error, display an error message to the user.
        console.log('Login failed', response.data);
      }
    } catch (error) {
      // Handle network or request errors here
      console.error('Network error', error);
    }
  };
  
  return (
    <div className="flex-container  custom-container">
      <div className="d-flex justify-content-center">
        <div className=" col-md-4 section1 ">
           <div className="d-flex flex-column align-items-center"> 
            <img
              src="./images/stock.jpeg"
              alt="images"
              style={{ width: "10%", display: "inline-block" }}
            ></img>
          {" "}
          <br></br>
          <div>
            <p>Bienvenu à Mstock</p>
          </div><br></br><br></br><br></br>
          <div>
            <img src="./images/log.svg" style={{ width: "80%" }}></img>
          </div>
        </div>
        </div>
        <div className=" col-md-4 section2  ">
          <div className="d-flex flex-column align-items-center">
            <p id="id">Sign In</p><br></br><br></br>
            <form onSubmit={handleForm}>
              <div className="form-group">
                <div className="inputbox ">
                  <label>Email</label>
                  <br />
                  <div className="custom-input">
                    <i className="fa fa-user white-icon"></i>
                    <input  type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder="Email "className="form-control form-control-lg"  style={{ paddingLeft: '60px' }} />
                  </div>
                </div>
              </div>

              <br></br>

              <div className="form-group">
                <div className="inputbox ">
                  <label>Password</label>
                  <br />
                  <div className="custom-input">
                    <i className="fa fa-lock white-icon"></i>
                    <input
                      type="password"
                      name="password"
            value={formData.password}
            onChange={handleInputChange}
            placeholder="Password"
                      className="form-control form-control-lg" style={{ paddingLeft: '60px' }}
                    />
                  </div>
                </div>
              </div>
              <br></br><br></br>
              <div><p>Mot de passe oublié?</p></div>
              <div>
                <button type="submit" className="btn btn-primary">
                  {" "}
                  Connecter
                </button>
              </div> <br></br>
              <div><p>Forget Password? create Acount</p></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Login;
